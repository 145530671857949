import { createStyles, Theme } from "@material-ui/core"

const FooterStyles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: "1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    footerText: {
      textAlign: "center",
      fontFamily: "Oswald",
      fontSize: "1em",
      fontWeight: 100,
      "& p": {
        margin: 0,
        lineHeight: '1.5em'
      },
    },
    footerLink: {
      textDecoration: "none",
      color: "black",
      cursor: "pointer",
    },
  })

export default FooterStyles
