import * as React from "react"
import { MuiThemeProvider, withStyles } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"

import Header from "../Header/Header"
import Footer from "../Footer/Footer"

import "./Layout.css"

import LayoutStyles from "./Layout.styles"
import { appTheme } from "../../styles/theme.styles"
import { ILayoutProps } from "./Layout.types"

const Layout = (props: ILayoutProps) => {
  const { classes, children } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Grid container style={{height: "100vh"}} >
      <MuiThemeProvider theme={appTheme}>
        <CssBaseline />
        <Header
          color="white"
          changeColorOnScroll={{
            height: 400,
            color: "dark",
          }}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <main className={classes.container}>{children}</main>
        <Footer />
      </MuiThemeProvider>
    </Grid>
  )
}

export default withStyles(LayoutStyles)(Layout)
