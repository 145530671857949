import * as React from "react"
import * as classNames from "classnames"

// @material-ui/core components
import { withStyles } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"

// @material-ui/icons
import Menu from "@material-ui/icons/Menu"

// Custom Components
import headerStyles from "./Header.styles"
import MainMenu from "./components/MainMenu/MainMenu"
import { Link } from "gatsby"

const useStyles = withStyles(headerStyles as any)

const Header = props => {
  const { classes, mobileOpen, handleDrawerToggle } = props

  React.useEffect(() => {

  })

  const { color, fixed, absolute } = props
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  })

  // TODO:: Find out if it is worth it to use the Hidden Component
  // smDown -> Hidden from Small BreakPoint and Down
  // mdUp -> Hidden from Medium BreakPoint and Up
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
          <h1 className={classes.theShineScaleText}>
            <Link className={classes.brandLink} to="/">
              THE SHINE SCALE
            </Link>
            <span style={{fontSize: "0.8rem", transform: "translate3D(3px, 2px, 0px)"}}>™</span>
          </h1>
      </Toolbar>
      {/*  <Hidden smDown implementation="css">*/}
      {/*    <MainMenu />*/}
      {/*  </Hidden>*/}
      {/*  /!* Displays Hamburger in Mobile*!/*/}
      {/*  <Hidden mdUp>*/}
      {/*    <IconButton*/}
      {/*      color="inherit"*/}
      {/*      aria-label="open drawer"*/}
      {/*      onClick={handleDrawerToggle}*/}
      {/*    >*/}
      {/*      <Menu />*/}
      {/*    </IconButton>*/}
      {/*  </Hidden>*/}
      {/*</Toolbar>*/}
      {/*/!* Mobile Drawer Implementatio *!/*/}
      {/*<Hidden mdUp implementation="js">*/}
      {/*  <Drawer*/}
      {/*    variant="temporary"*/}
      {/*    anchor={"right"}*/}
      {/*    open={mobileOpen}*/}
      {/*    classes={{*/}
      {/*      paper: classes.drawerPaper,*/}
      {/*    }}*/}
      {/*    onClose={handleDrawerToggle}*/}
      {/*  >*/}
      {/*    <div className={classes.appResponsive}>*/}
      {/*      <MainMenu onMenuItemClick={handleDrawerToggle} />*/}
      {/*    </div>*/}
      {/*  </Drawer>*/}
      {/*</Hidden>*/}
    </AppBar>
  )
}

export default useStyles(Header)
