import * as React from "react"
import { Link } from "gatsby"
import FooterStyles from "./Footer.styles"
import { IProps } from "./Footer.types"
import { Container, withStyles } from "@material-ui/core"

const Footer = ({ classes }: IProps) => (
  <footer className={classes.root}>
    <Container maxWidth="md">
      <div className={classes.footerText}>
        <p>© {new Date().getFullYear()} Intivine, Inc.</p>
        <p>
          <Link className={classes.footerLink} to="/privacy">
            Privacy Policy
          </Link>
        </p>
        <p>
          Made with ❤️{" "}by
          <a
            style={{ textDecoration: "none", color: "black" }}
            href="https://divelement.io"
            target="_blank"
            rel="noopener"
          >
            {" "}DIVELEMENT
          </a>
        </p>
        <p style={{ fontSize: "12px" }}>
          The Shine Scale™ and results from the quiz are meant to be fun,
          educational, and support additional work if engaged. Answers will be
          used to calculate results only to be shared with the contact
          information provided.
          <br /> We do not accept responsibility for any liability that arises
          from using this quiz or the results.
        </p>
      </div>
    </Container>
  </footer>
)

export default withStyles(FooterStyles)(Footer)
