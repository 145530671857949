// Load Oswald Typeface Font from Packages
require("typeface-oswald")
//Load Permanent Marker Typeface Font from Packages
require("typeface-permanent-marker")

//gatsby-background-image support for Safari and (bleh) IE
exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    // import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

const React = require("react")
const Layout = require("./src/components/Layout/Layout").default

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
