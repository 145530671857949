import { createMuiTheme, Theme } from "@material-ui/core"
import { Palette } from "@material-ui/core/styles/createPalette"
import { TypographyOptions } from "@material-ui/core/styles/createTypography"
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme"

const typography = (pallete: Palette): Partial<TypographyOptions> => ({
  fontFamily: ["Oswald", "Helvetica"].join(", "),
  fontSize: 16
})

// All global theme personalization go here
export const appTheme: Theme = createMuiTheme({
  typography
})
